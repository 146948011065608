<template>
  <div>
    <CCard>
      <CCardBody>
        <CRow>
          <CCol sm="8">
            <h4 id="traffic" class="card-title mb-0">List Authorized Person</h4>
            <div class="small text-muted">{{date(new Date(), 'MMMM YYYY')}}</div>
          </CCol>
          <CCol sm="4" class="col align-self-center row justify-content-end">
            <CButton
							class="my-2 text-center"
							color="danger"
							variant="outline"
							square
							size="sm"
							@click="generatePdf"
						>
							Generate PDF
						</CButton> &nbsp;
				    <download-excel
              class="btn btn-default"
              :data="filteredItems"
              :fields="jsonFields"
              worksheet="Sheet1"
              name="list-authorized-person.xls"
            >
              <CButton
                color="success"
                variant="outline"
                square
                size="sm"
              >
                Download Excel
              </CButton>
            </download-excel>
			    </CCol>
        </CRow>

        <CRow class="mt-5">
          <CCol sm="3">
						<CInput
							type="text"
							label="Search.."
							placeholder=""
							description="Masukkan kata kunci pencarian."
							v-model="search"
							required
						/>
					</CCol>
					<CCol sm="3">
            <CSelect 
							:value.sync="keyword"
							name="keyword"
							label="Filter By"
							:options="keywords"
						/>
          </CCol>
          <CCol sm="3">
            <CButton
              style="margin-top: 30px;"
              color="primary"
              variant="outline"
              square
              size="sm"
              @click="filterRange"
            >
              Apply
            </CButton> &nbsp;
            <CButton
              style="margin-top: 30px;"
              color="success"
              variant="outline"
              square
              size="sm"
              @click="filterRefresh"
            >
              Refresh List
            </CButton>
          </CCol>
        </CRow>

        <CDataTable
					hover
					striped
					border
					small
					sorter
					:items="filteredItems"
					:fields="fields"
          :items-per-page="perPage"
				  pagination
					:loading="isLoading"
				>
					<template #id="{item}">
						<td align="center">{{ filteredItems.map(function(x) {return x; }).indexOf(item)+1 }}</td>
					</template>
				</CDataTable>

        <vue-html2pdf
					:show-layout="true"
					:float-layout="false"
					:enable-download="true"
					:preview-modal="false"
					:paginate-elements-by-height="1400"
					filename="Lintasarta-List-Auth-Person"
					:pdf-quality="2"
					:manual-pagination="true"
					pdf-format="a4"
					pdf-orientation="portrait"
					pdf-content-width="100%"
					@hasStartedGeneration="hasStartedGeneration()"
					@hasGenerated="hasGenerated($event)"
					:html-to-pdf-options="htmlToPdfOptions"
					ref="html2Pdf"
					class="d-none"
				>
          <section slot="pdf-content" id="content">
            <ThePdfHeader title="Report List Auth Person" :reportFrom="date(new Date(), 'DD MMMM YYYY')" :reportTo="date(new Date(), 'DD MMMM YYYY')" :reportFor="reportFor" />
          
            <CDataTable
					    border
					    small
					    :items="filteredItems"
					    :fields="fields"
              :items-per-page="perPage"
				    >
              <template #id="{item}">
						    <td align="center">{{ filteredItems.map(function(x) {return x; }).indexOf(item)+1 }}</td>
					    </template>
            </CDataTable>
          </section>
        </vue-html2pdf>

      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import http from '@/utils/http-common';
import moment from 'moment';

import VueHtml2pdf from 'vue-html2pdf';
import ThePdfHeader from '@/containers/ThePdfHeader.vue';

export default {
  name: 'ListAuthPerson',
  components: {
    VueHtml2pdf, ThePdfHeader
  },
  data () {
		return {
      company_id: +localStorage.getItem('company_id'),
      role: +localStorage.getItem('role'),
      users: [],
      search: '',
      filteredItems: [],
			fields: [
        { key: 'id', label: 'No' },
        { key: 'created_at', label: 'Registered' },
        'company_name',
        'name',
        'role',
        { key: 'site_name', label: 'Data Center' },
        'email',
        'mobile_phone',
        'status', 
      ],
			currentPage: 1,
			perPage: 15,
			totalRows: 1,
			isLoading: true,
      keyword: 'Company Name',
      keywords: [
        'Name', 'Company Name', 'Data Center', 'Role'
      ],
      reportFor: 'Company Name: All',
      jsonFields: {
        No: {
          field: "id",
          callback: (value) => {
            return this.filteredItems.map(function(x) {return x.id; }).indexOf(value)+1;
          },
        },
        "Registered": 'created_at',
        "Company Name": 'company_name',
        "Name": 'name',
        "Role": 'role',
        "Data Center": 'site_name',
        "Email": 'email',
        "Phone": 'mobile_phone',
        "Status": 'status',
      },
      htmlToPdfOptions: {
				margin: 0,
				filename: 'Lintasarta-Auth-Person',
				'pdf-quality': '2',
				jsPDF: {
					unit: 'mm',
					format: 'a4',
					orientation: 'portrait'
				},
				html2canvas: {
					dpi: 300,
					letterRendering: true,
					useCORS: true
				}
			},
    }
  },
  paginationProps: {
		align: 'right',
		doubleArrows: false,
		previousButtonHtml: 'prev',
		nextButtonHtml: 'next'
	},
  methods: {
    generatePdf () {
			this.$refs.html2Pdf.generatePdf();
		},
    getUsers() {
			let self = this;
			return http.get('/report/list-auth-person')
				.then(function (response) {
          let list = response.data.data;
          list = list.filter(function(item) {
    				return item.role !== 'Guest';  
					});

          if (self.role === 4 || self.role > 5) {
            list = list.filter(function(item) {
    				  return item.company_id === self.company_id;  
					  });
          }

          console.log(list)

					self.users = list;
          self.filteredItems = list;
					self.isLoading = false;
				}).catch(function (error) {
					console.log(error);
				});
		},
    date(value, format) {
      return moment(String(value)).locale('Id').format(format);
    },
    filterRange() {
      let self = this;
      self.filteredItems = [];

      let data = self.users.filter(item => {
        if (self.keyword === 'Role') {
          self.reportFor='Role Name: ' + self.search.toLowerCase();
          return item.role.toLowerCase().includes(self.search.toLowerCase());
        } else if (self.keyword === 'Data Center') {
          self.reportFor='Data Center: ' + self.search.toLowerCase();
          return item.site_name.toLowerCase().includes(self.search.toLowerCase());
        } else if (self.keyword === 'Name') {
          self.reportFor='Name: ' + self.search.toLowerCase();
          return item.name.toLowerCase().includes(self.search.toLowerCase());
        }else {
          self.reportFor='Company Name: ' + self.search.toLowerCase();
          return item.company_name.toLowerCase().includes(self.search.toLowerCase());
        }
      })

      self.filteredItems = data;
    },
    filterRefresh() {
      this.filteredItems = this.users;
      this.reportFor = 'Company Name: All'
      this.search = '';
    },
  },
  mounted: function(){
		this.getUsers();
	}
}
</script>

<style scoped>
#content {
  background: #fff;
  font-size: 7.5px;
  padding: 12px;
}
</style>
